<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <!-- 계좌설정 -->
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            계좌설정
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
        </span>
        <hr class="pb-1">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                class="font-small-3"
                label="계좌화면 상단내용"
                label-for="main-notice"
                label-cols-md="2"
              >
                <b-form-textarea
                  v-if="fetchSetAccount"
                  id="main-notice"
                  :value="fetchSetAccount.mainNotice"
                  class="font-small-3"
                  size="sm"
                  placeholder="계좌화면 상단내용"
                  style="color:rgb(229,166,48)"
                  rows="6"
                  @input="updateParam('fetchSetAccount','mainNotice', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="font-small-3"
                label="계좌화면 하단내용"
                label-for="main-notice"
                label-cols-md="2"
              >
                <b-form-textarea
                  v-if="fetchSetAccount"
                  id="sub-notice"
                  :value="fetchSetAccount.subNotice"
                  class="font-small-3"
                  size="sm"
                  placeholder="계좌화면 하단내용"
                  style="color:rgb(229,166,48)"
                  rows="6"
                  @input="updateParam('fetchSetAccount','subNotice', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="font-small-3"
                label="계좌번호"
                label-for="account-no"
                label-cols-md="4"
              >
                <b-form-input
                  v-if="fetchSetAccount"
                  id="account-no"
                  :value="fetchSetAccount.accountNo"
                  class="font-small-3"
                  size="sm"
                  placeholder="계좌번호"
                  style="color:rgb(229,166,48)"
                  @input="updateParam('fetchSetAccount','accountNo', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="font-small-3"
                label="입금은행"
                label-for="account-bank"
                label-cols-md="4"
              >
                <b-form-input
                  v-if="fetchSetAccount"
                  id="site-status"
                  :value="fetchSetAccount.accountBank"
                  class="font-small-3"
                  size="sm"
                  placeholder="입금은행"
                  style="color:rgb(229,166,48)"
                  @input="updateParam('fetchSetAccount','accountBank', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="font-small-3"
                label="예금주"
                label-for="account-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-if="fetchSetAccount"
                  id="account-name"
                  :value="fetchSetAccount.accountName"
                  class="font-small-3"
                  size="sm"
                  placeholder="예금주"
                  style="color:rgb(229,166,48)"
                  @input="updateParam('fetchSetAccount','accountName', $event)"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col
              id="basic1-btn"
              cols="12"
            >
              <b-button
                ref="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="w-auto content-right"
                size="sm"
                @click="submit('updateSetAccount')"
              >
                저장
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormTextarea,
  BBadge,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_ACCOUNT } from '@/store/settings/action'
import { UPDATE_SET_ACCOUNT } from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BBadge,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  setup() {

  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      disabled: false,
      isHidden1: true, // 계좌 설정
      check1: 'false',
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalUsers: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      info: {
        // 기본 설정
        mainNotice: '계좌상단 공지입니다\n'
            + '🚀 안녕하세요 운영팀입니다.\n'
            + '🚀 계좌번호에 관한 공지사항입니다.',
        subNotice: '계좌하단 공지입니다\n'
            + '🚀 안녕하세요 운영팀입니다.\n'
            + '🚀 계좌번호에 관한 상세공지사항입니다.',
        accountNo: '123-456-7890',
        accountBank: '카카오뱅크',
        accountName: '(주)골드문',
      },
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'userId', sortable: false, label: '아이디', thClass: 'col2',
        },
        {
          key: 'type', sortable: false, label: '타입', thClass: 'col3',
        },
        {
          key: 'requestDate', sortable: false, label: '요청일', thClass: 'col4',
        },
        {
          key: 'status', sortable: false, label: '상태', thClass: 'col5',
        },
      ],
      tableItems1: [
        {
          no: 1,
          userId: '홍길동',
          type: '충전요청(고객센터문의)',
          requestDate: '2022-08-31 19:20:37',
          status: '충전요청',
        },
        {
          no: 2,
          userId: '문신돼지국밥1',
          type: '충전요청(고객센터문의)',
          requestDate: '2022-10-04 13:10:22',
          status: '충전요청',
        },
        {
          no: 3,
          userId: '문신돼지국밥2',
          type: '충전요청(고객센터문의)',
          requestDate: '2022-10-04 13:10:22',
          status: '충전요청',
        },
        {
          no: 4,
          userId: '문신돼지국밥3',
          type: '충전요청(고객센터문의)',
          requestDate: '2022-10-04 13:10:22',
          status: '충전요청',
        },
        {
          no: 5,
          userId: '문신돼지국밥4',
          type: '충전요청(고객센터문의)',
          requestDate: '2022-10-04 13:10:22',
          status: '충전요청',
        },
      ],
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetAccount: 'fetchSetAccount',
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
    settingsStore() {
      return this.$store.state.settingsStore.fetchSetAccount[0]
    },
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.siteAdminData = this.fetchSetSiteAdmin
    // this.authSite = this.fetchSetSiteAdmin.map(item => item.authSite)
    // this.siteSelected = this.authSite[0]
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...settingsStore.mapActions({
      $fetchSetAccount: FETCH_SET_ACCOUNT,
      $updateSetAccount: UPDATE_SET_ACCOUNT,
    }),
    updateParam(t, e, v) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = { t, e, v }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateParam', events)
      // console.log(this.$store.mainNotice('settingsStore/mainNotice'))
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetAccount({
        site: mySite,
      })
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    confirmYes(target) {
      const mySite = this.siteSelected
      try {
        if (target === 'updateSetAccount') {
          this.$updateSetAccount({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
          this.isHidden1 = true
          this.check1 = 'false'
        })
      }
    },
  },
  // submit() {
  //   Swal.fire({
  //     title: '변경내용을 저장하시겠습니까?',
  //     // text: Number(this.desiredBet).toLocaleString(),
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //     background: 'rgb(3,11,37)',
  //     customClass: {
  //       cancelButton: 'btn btn-dark',
  //       confirmButton: 'btn btn-primary mr-1',
  //     },
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       this.confirmYes1()
  //     }
  //   })
  // },
  // // confirm Yes
  // confirmYes1() {
  //   Swal.fire({
  //     title: '저장 완료',
  //     // text: Number(this.desiredBet).toLocaleString(),
  //     icon: 'success',
  //     timer: 5000,
  //     background: 'rgb(3,11,37)',
  //     customClass: {
  //       confirmButton: 'btn btn-primary',
  //     },
  //   })
  //   // this.createBetHistory(this.desiredBet)
  // },

  //  onFiltered(filteredItems) {
  //    // Trigger pagination to update the number of buttons/pages due to filtering
  //    this.totalRows = filteredItems.length
  //    this.currentPage = 1
  //  },
  // },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}

/*.col1 {*/
/*  width: 10%;*/
/*}*/
/*.col2 {*/
/*  width: 20%;*/
/*}*/
/*.col3 {*/
/*  width: 20%;*/
/*}*/
/*.col4 {*/
/*min-width: 200px;*/
/*}*/
/*.col5 {*/
/*  min-width: 140px;*/
/*}*/
/*!* Responsive: Use a vertical column if under 450px wide *!*/
/*@media screen and (max-width: 805px) {*/
/*  .col1 {*/
/*    min-width: 10px;*/
/*  }*/
/*  .col2 {*/
/*    min-width: 200px;*/
/*  }*/
/*  .col3 {*/
/*    min-width: 150px;*/
/*  }*/
/*  .col4 {*/
/*    min-width: 80px;*/
/*  }*/
/*  .col5 {*/
/*    min-width: 140px;*/
/*  }*/
/*}*/
</style>
